<template>
  <div>
    <v-container fluid style="position: relative;">
      <v-row>
        <!-- Columna para el Formulario -->
        <v-col cols="12">
          <v-row elevation="6" style="background-color: #fffdfd;" class="responsive-buttons">
            <!-- Botón de Consulta por Embarcación -->
            <v-col cols="12" sm="6" md="3" lg="3" class="d-flex justify-center">
              <v-btn color="blue darken-3" dark @click="abrirModal('consultaEmbarcacion')" class="btn-responsive btn-embarcacion">
                <v-icon left>mdi-ship-wheel</v-icon>
                <span class="button-text">Consulta por Embarcación</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="d-none d-md-flex align-center">
              <div style="border-left: 4px solid #0c0c0c; height: 50px; margin: 0 16px;"></div>
            </v-col>

            <!-- Botón de Consulta por Área -->
            <v-col cols="12" sm="6" md="3" lg="2" class="d-flex justify-center">
              <v-btn color="yellow darken-1" dark @click="abrirModal('consultaZona')" class="btn-responsive">
                <v-icon icon="fa:fas fa-search"></v-icon>
                <span class="button-text">Consulta por Área</span>
              </v-btn>
            </v-col>

            <!-- Botón de Registrar Área -->
            <v-col cols="12" sm="6" md="3" lg="2" class="d-flex justify-center">
              <v-btn color="green darken-3" dark @click="abrirModal('registrarArea')" class="btn-responsive">
                <v-icon left>mdi-plus</v-icon>
                <span class="button-text">Registrar Área</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>


        <!-- Columna para el Mapa -->
        <v-col cols="12">
          <v-card class="map-card" elevation="0">
            <v-card-title class="text-h6 green--text text--darken-3">
              <!-- Lista horizontal de tipos de eventos -->
              <v-row class="mt-3 event-types-container"  align="center" no-gutters>
                <v-col
                  v-for="item in itemsTipoEvento"
                  :key="item.value"
                  class="d-flex align-center event-type-item"
                  cols="auto"
                >
                  <v-avatar size="36" class="mr-2">
                    <img :src="getIconUrl(item.value)" alt="icon" />
                  </v-avatar>
                  <span class="event-type-text">{{ item.text }}</span>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <div class="map-container">
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css" />
                <l-map
                  ref="mapLeaflet"
                  class="map"
                  :zoom="zoom"
                  :center="center"
                >
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                  <l-marker
                    v-for="vessel in vessels"
                    :key="vessel.mmsi"
                    :lat-lng="vessel.point.lat != null && vessel.point.lon != null
                     ? [vessel.point.lat, vessel.point.lon]
                     : [0, 0]"
                    @click="mostrarEventos(vessel)"
                    :ref="'marker-' + vessel.name"
                  >
                    <l-icon
                      :icon-url="getIconUrl(vessel.eventType)"
                      :icon-size="[40, 40]"
                      :icon-anchor="[20, 20]"
                    ></l-icon>
                    <l-popup>
                      <div class="popup-content">
                        <h3>{{ vessel.name }}</h3>
                        <div v-if="vessel">
                          <v-icon left>{{ getIconName(vessel.eventType) }}</v-icon>
                          <p>
                            <strong>Tipo de evento:</strong>
                            {{ traducirTipoEvento(vessel.eventType) }}
                          </p>
                        </div>
                        <div v-else>
                          <p>No hay eventos disponibles para este buque.</p>
                        </div>
                      </div>
                    </l-popup>
                  </l-marker>
                </l-map>
              </div>
            </v-card-text>
          </v-card>

          <!-- Enlaces externos -->
          <v-row  justify="flex">
            <v-col cols="12" sm="auto" class="d-flex justify-center">
              <a href="https://sc-production.skylight.earth/login" target="_blank" rel="noopener noreferrer">
                <v-btn color="#01579B" text dark class="external-link-btn">Skylight</v-btn>
              </a>
            </v-col>
            <v-col cols="12" sm="auto" class="d-flex justify-center">
              <a href="https://www.vesselfinder.com/login" target="_blank" rel="noopener noreferrer">
                <v-btn color="#004D40" text dark class="external-link-btn">Vessel Finder</v-btn>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Navigation Drawer -->
      <v-navigation-drawer
        right
        rail
        hover
        expand-on-hover
        width="300"
        class="navigation-drawer"
        :class="{'drawer-mobile': $vuetify.breakpoint.smAndDown}"
      >
        <v-toolbar flat dark>
          <v-toolbar-title class="d-flex align-center">
            <v-icon v-if="!isExpanded" class="mr-2">mdi-menu</v-icon>
            Eventos por Embarcación
          </v-toolbar-title>
        </v-toolbar>

        <v-list dense>
          <v-list-item
            v-for="(item, index) in tableData"
            :key="index.id"
            @click="focusOnEvent(item)"
            class="event-list-item"
          >
            <v-list-item-icon>
              <v-img
                :src="getIconUrl(item.eventType)"
                alt="icono del evento"
                max-width="24"
              />
            </v-list-item-icon>
            <v-list-item-content class="d-flex justify-space-between align-center">
              <div>
                <v-list-item-title>
                  <span class="font-weight-bold">{{ item.boatName || 'Sin nombre' }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ traducirTipoEvento(item.eventType) }}
                  <br />
                  {{ formatDate(item.timestamp) }}
                </v-list-item-subtitle>
              </div>
              <v-btn
                @click.stop="redirectToVessel(item.vesselId)"
                color="primary"
                small
                class="ml-2"
              >
                Ver más
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-dialog v-model="showModal" max-width="500px">
        <v-card>
          <v-card-title class="headline">
            {{ modalTitle }}
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <!-- Formulario para registrar área -->
              <template v-if="modalType === 'registrarArea'">
                <v-card-text>
                  <v-form ref="form">
                    <v-text-field
                      label="Nombre del Área"
                      v-model="nuevaArea.name"
                      :rules="[rules.required]"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="ID del Área (AOI)"
                      v-model="nuevaArea.aoi_id"
                      :rules="[rules.required]"
                      outlined
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cerrarModal">Cancelar</v-btn>
                  <v-btn color="green darken-3" text @click="registrarArea">Registrar</v-btn>
                </v-card-actions>
              </template>
              <!-- Formulario para consulta por zona -->
              <template v-if="modalType === 'consultaZona'">
                <v-select
                  v-model="zonasSeleccionadas"
                  :items="areas"
                  item-text="name"
                  item-value="aoi_id"
                  label="Seleccionar Zonas"
                  outlined
                  :rules="[rules.required]"
                  multiple
                ></v-select>
                <v-text-field
                  v-model="fechaInicio"
                  label="Fecha de Inicio"
                  type="date"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="fechaFin"
                  label="Fecha de Fin"
                  type="date"
                  :rules="[rules.required]"
                  outlined
                ></v-text-field>

                <v-btn color="blue darken-3" block @click="consultarZona">
                  Consultar
                </v-btn>
              </template>

              <!--Formulario para consultar embarcion-->
              <template v-if="modalType === 'consultaEmbarcacion'">
                <v-form ref="form">
                  <v-autocomplete
                    label="Nombre de la embarcación"
                    v-model="consultaEmbarcacion.cod_tracing"
                    :items="allBoats"
                    item-text="boat_name"
                    item-value="cod_tracing"
                    :rules="[rules.required]"
                    outlined
                    clearable
                    dense
                    solo
                    placeholder="Selecciona o escribe el nombre de la embarcación"
                  ></v-autocomplete>

                  <v-select
                    v-model="consultaEmbarcacion.event_types"
                    :items="itemsTipoEvento"
                    item-text="text"
                    item-value="value"
                    label="Seleccionar Tipos de Evento"
                    outlined
                    :rules="[rules.required]"
                    multiple
                  ></v-select>

                  <v-text-field
                    v-model="consultaEmbarcacion.start_date"
                    label="Fecha de Inicio"
                    type="date"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>

                  <v-text-field
                    v-model="consultaEmbarcacion.end_date"
                    label="Fecha de Fin"
                    type="date"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>


                  <v-btn color="blue darken-3" block @click="consultarEmbarcacion">
                    Consultar
                  </v-btn>
                </v-form>
              </template>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrarModal">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- Alert -->
      <v-alert
        v-if="showAlert && message !== null"
        class="custom-alert mt-3"
        border="left"
        elevation="2"
        shaped
      >
        <v-icon class="mr-3" color="white" size="24">mdi-information</v-icon>
        {{ message }}
      </v-alert>

      <!-- Loading Spinner -->
      <div v-if="Loading" class="spinner-overlay">
        <div class="spinner-box">
          <div class="spinner"></div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { LMap, LTileLayer, LMarker, LIcon, LPopup } from "vue2-leaflet";
  import axios from 'axios';
  import {
    vesselTrackingAndHistory,
    areaOfInterest,
    allAreaOfInterest, eventsQueryByArea, consultationByBoat, allBoatsQuery
  } from "./../config.js";
  import L from 'leaflet';
  import 'leaflet-polylinedecorator';
  import Swal from "sweetalert2";
  export default {
    props: ["cod_tracing"],
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LIcon,
      LPopup,
    },
    data() {
      return {
        zonasSeleccionadas:[],
        todaszonasSeleccionadas:[],
        vessels: [],
        decorators: [],
        allVessels: [],
        isVesselsReady: false,
        Loading: false,
        dialog: false,
        message: null,
        codigoNumerico: "",
        tipoEvento: [],
        itemsTipoEvento: [
          { text: "Encuentro Estándar", value: "standard_rendezvous", icon: "mdi-account-group" },
          { text: "Encuentro Oscuro", value: "dark_rendezvous", icon: "mdi-weather-night" },
          { text: "Pesca", value: "viirs", icon: "mdi-fish" },
          { text: "Historial de actividad pesquera", value: "fishing_activity_history", icon: "mdi-chart-timeline" },
          { text: "Rango de Velocidad", value: "speed_range", icon: "mdi-speedometer" },
          { text: "Detecciones de embarcaciones", value: "detection", icon: "mdi-radar" },
          {text:  "Entrada", value: "aoi_visit", icon: "mdi-radar" },
        ],
        searchBoat: '',
        allBoats: [],
        showAlert: false,
        selectedEvent: null,
        eventosSeleccionados: [],
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 13,
        center: [12.548883, -81.718130],
        map: null,
        routeLayer: null,
        routes: [],
        startMarkers: [],
        endMarkers: [],
        showEventsButton: false,
        selectedVessel: null,
        drawerVisible: false,
        drawer: false,
        imag: require('@/assets/iconic.png'),
        dark: require('@/assets/dark.png'),
        history: require('@/assets/history.png'),
        detection: require('@/assets/detection.png'),
        standard: require('@/assets/estandar.png'),
        speed: require('@/assets/speed_range.png'),
        defaul: require('@/assets/deafaul.png'),
        entry: require('@/assets/Entrada.png'),
        menu: false,
        selectedFilter: null,
        opcionesFiltro: [
          "Últimas 6 Horas",
          "Últimas 12 Horas",
          "Últimas 24 Horas",
          "Últimas 48 Horas",
          "Últimas 72 Horas",
          "Última Semana",
          "Últimas 2 Semanas",
          "Último Mes",
          "Rango Personalizado",
        ],
        customDateRange: null,
        tituloBoton: "Últimos Eventos",
        headers: [
          { text: "Embarcación", value: "boatName" },
          { text: "Tipo de Evento", value: "eventType" },
          { text: "Fecha y Hora", value: "timestamp" },
          { text: "Acción", value: "action", sortable: false },
        ],
        tableData: [],
        showTable: false,
        selectedBoatName: "Sin Nombre",
        vesselId: "Id No Encontrado",
        suggestions: [],
        suggestionsMenu: false,
        searchHistory: [],
        endDate: null,
        startDate: null,
        menuStart: false,
        menuEnd: false,
        showModal: false,
        nuevaArea: {
          aoi_id: '',
          name: ''
        },
        rules: {
          required: value => !!value || 'Este campo es obligatorio',
        },
        areas:[],
        modalType: '',
        modalTitle: '',
        zonaConsulta: '',
        zonaSeleccionada: '',
        consultaEmbarcacion: {
          cod_tracing: '',
          event_types: [],
          start_time: '',
          end_time: '',
        },
        resultadosConsulta: [],
        ubicacion: {
          lat: 0,
          lon:0

        },

      };
    },
    created() {
      this.loadData();
      this.routes.forEach((route) => route.remove());
      this.routes = [];
      this.decorators.forEach((decorator) => decorator.remove());
      this.decorators = [];
    },
    computed:{
      isExpanded() {
        return this.$refs.drawer && this.$refs.drawer.expanded;
      },
    },
    methods: {

      focusOnEvent(event) {
        const vessel = this.vessels.find(v => v.name === event.boatName);

        if (vessel && vessel.point.lat != null && vessel.point.lon != null) {
          this.center = [vessel.point.lat, vessel.point.lon];

          const refKey = `marker-${vessel.name}`;

          this.zoom = 13;
          const markerRef = this.$refs[refKey];
          if (markerRef && markerRef[0] && markerRef[0].$leafletObject) {
            markerRef[0].$leafletObject.openPopup();
          }

        }
      },

      loadData() {
        const now = new Date();
        const twoDaysAgo = new Date(now);
        twoDaysAgo.setDate(now.getDate() - 2);


        const codTracing = this.$route.params.cod_tracing;
        if (codTracing) {
          this.codigoNumerico = codTracing;
        }

        this.getAllArea();
        this.getBoat();
      },

      abrirModal(tipo) {
        this.modalType = tipo;

        if (tipo === 'registrarArea') {
          this.modalTitle = 'Registrar Área';
        } else if (tipo === 'consultaZona') {
          this.modalTitle = 'Consulta por Zona de Área';
        } else if (tipo === 'consultaEmbarcacion') {
          this.modalTitle = 'Consulta por Embarcación';
        }

        this.showModal = true;
      },

      cerrarModal() {
        this.showModal = false;

        if (this.modalType === 'registrarArea') {
          this.nuevaArea = { aoi_id: '', name: '' };
        } else if (this.modalType === 'consultaZona') {
          this.zonaConsulta = '';
        } else if (this.modalType === 'consultaEmbarcacion') {
          this.embarcacionConsulta = '';
        }

        this.modalType = '';
      },

      async registrarArea() {

        this.loading = true;

        const valido = await this.$refs.form.validate();
        if (!valido) {
          this.loading = false;
          return;
        }

        try {
          await axios.post(areaOfInterest, this.nuevaArea);

          Swal.fire({
            title: 'Éxito',
            text: 'El área ha sido registrada correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });

          this.cerrarModal();
        } catch {
          this.$store.commit("LOADER", false);
          Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al registrar el área. Inténtalo nuevamente.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        } finally {
          this.$store.commit("LOADER", false);
        }
      },

      async getAllArea() {
        try {
          const response = await axios.get(allAreaOfInterest);
          this.areas = response.data.data;

          this.todaszonasSeleccionadas = this.areas.map((area) => area.aoi_id);
          const now = new Date();
          this.fechaFin = now.toISOString().split('T')[0];
          this.fechaInicio = new Date(now.setDate(now.getDate() - 1))
            .toISOString()
            .split('T')[0];


          await this.consultarTodasZona();
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un problema al obtener las áreas.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      },

      async getBoat(){
        try {
          const response = await axios.get(allBoatsQuery);
          this.allBoats = response.data.boats;
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un problema al obtener las áreas.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      },

      centrarMapaEnBarco() {
        const boatName = this.$route.query.boat_name;
        if (boatName) {
          const vessel = this.vessels.find((v) => v.boat_name === boatName);
          if (vessel) {
            this.center = [vessel.point.lat, vessel.point.lon];
            this.zoom = 14;

            this.$nextTick(() => {
              const markerRef = this.$refs[`marker-${vessel._id}`];
              if (markerRef && markerRef[0] && markerRef[0].$el) {
                const leafletMarker = markerRef[0].$el._leaflet_id
                  ? markerRef[0].$el
                  : markerRef[0].mapObject;
                if (leafletMarker) {
                  leafletMarker.openPopup();
                }
              }
            });
          }
        }
      },

      formatDateWithTime(date, time) {
        return `${date}T${time}`;
      },

      async consultarEmbarcacion() {
        // Limpieza inicial
        this.routes.forEach((route) => route.remove());
        this.decorators.forEach((decorator) => decorator.remove());
        this.routes = [];
        this.decorators = [];

        try {
          // Validación del formulario
          const formValido = await this.$refs.form.validate();
          if (!formValido) {
            Swal.fire({
              icon: "warning",
              title: "Formulario incompleto",
              text: "Por favor completa todos los campos requeridos.",
            });
            return;
          }

          // Combinar fecha y hora predeterminada
          const start_time = this.formatDateWithTime(this.consultaEmbarcacion.start_date, "00:00:00");
          const end_time = this.formatDateWithTime(this.consultaEmbarcacion.end_date, "23:59:59");

          // Construcción del payload
          const payload = {
            cod_tracing: this.consultaEmbarcacion.cod_tracing,
            event_types: this.consultaEmbarcacion.event_types,
            start_time: start_time, // Fecha con hora 00:00:00
            end_time: end_time,     // Fecha con hora 23:59:59
          };


          // Petición a la API
          const response = await axios.post(consultationByBoat, payload);

          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "¡Consulta exitosa!",
              text: "Consulta realizada con éxito.",
            });

            this.showModal = false;

            const selectedBoat = this.allBoats.find(
              (boat) => boat.cod_tracing === this.consultaEmbarcacion.cod_tracing
            );

            // Procesar eventos
            const eventosProcesados = response.data.eventsData.map((event) => ({
              ...event,
              vessels: [
                {
                  name: selectedBoat ? selectedBoat.boat_name : "Unknown Vessel",
                  mmsi: selectedBoat ? selectedBoat.cod_tracing : null,
                  vessel_id: response.data.vesselData.vessel_id,
                  eventId: event.event_id,
                  point: {
                    lat: response.data.vesselData.point?.lat ?? null,
                    lon: response.data.vesselData.point?.lon ?? null,
                  },
                  eventType: event.event_type,
                  details: {
                    cog: response.data.vesselData.cog,
                    sog: response.data.vesselData.sog,
                    time: response.data.vesselData.time,
                  },
                },
              ],
            }));


            // Asigna a vessels y tableData
            this.vessels = eventosProcesados.flatMap((evento) => evento.vessels);

            this.tableData = this.vessels.map((vessel) => ({
              boatName: vessel.name,
              eventType: vessel.eventType,
              timestamp: vessel.details?.time,
              vesselId: vessel.vessel_id,
            }));

            // Centra el mapa en la primera coordenada válida
            const firstValidVessel = this.vessels.find(
              (vessel) => vessel.point?.lat && vessel.point?.lon
            );
            if (firstValidVessel) {
              this.center = [firstValidVessel.point.lat, firstValidVessel.point.lon];
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Error en la consulta",
              text: "Hubo un problema al realizar la consulta.",
            });
            this.showModal = false;
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error inesperado",
            text: "Error al realizar la consulta. Intenta nuevamente.",
          });
          this.showModal = false;
        }
      },

      async consultarZona() {
        this.routes.forEach((route) => route.remove());
        this.routes = [];
        this.decorators.forEach((decorator) => decorator.remove());
        this.decorators = [];

        this.Loading = true;
        if (!this.zonasSeleccionadas || this.zonasSeleccionadas.length === 0 || !this.fechaInicio || !this.fechaFin) {
          Swal.fire({
            title: 'Error',
            text: 'Por favor, completa todos los campos.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
          return;
        }

        const areasAConsultar = this.zonasSeleccionadas.includes('all')
          ? this.areas.map((area) => area.aoi_id)
          : this.zonasSeleccionadas;

        const resultados = [];
        const errores = [];

        try {
          for (const aoiId of areasAConsultar) {
            const requestBody = {
              startTime: new Date(this.fechaInicio).toISOString(),
              endTime: new Date(this.fechaFin).toISOString(),
              aoiId,
            };

            try {
              const response = await axios.post(eventsQueryByArea, requestBody);

              // Procesar eventos y embarcaciones
              const datosProcesados = await Promise.all(
                response.data.events.flatMap(async (event) => {
                  try {
                    // Obtener las primeras coordenadas
                    const primerasCoordenadas = await this.obtenerPrimerasCoordenadas(event.event_id);

                    // Seleccionar una coordenada (por ejemplo, la primera, si está disponible)
                    const coordenadaSeleccionada = primerasCoordenadas[0] || null;

                    return (event.vessels || []).map((vessel) => {
                      const latitude = coordenadaSeleccionada?.lat || vessel.details?.latitude;
                      const longitude = coordenadaSeleccionada?.lon || vessel.details?.longitude;

                      this.ubicacion.lat = latitude;
                      this.ubicacion.lon = longitude;

                      return {
                        eventId: event.event_id,
                        eventType: event.event_type,
                        startTime: event.start?.time,
                        endTime: event.end?.time,
                        name: vessel.name,
                        country: vessel.display_country,
                        mmsi: vessel.mmsi,
                        vessel_id: vessel.vessel_id,
                        details: vessel.details,
                        point: {
                          lat: latitude,
                          lon: longitude,
                        },
                        hasValidCoordinates: latitude != null && longitude != null,
                      };
                    });
                  } catch (error) {
                    return [];
                  }
                })
              );

              resultados.push(...datosProcesados.flat());
            } catch (error) {
              errores.push(aoiId);
            }
          }

          if (resultados.length > 0) {
            this.allVessels = resultados;
            this.vessels = [...this.allVessels];

            // Estructura de tableData, similar a las otras funciones
            this.tableData = this.vessels.map((vessel) => ({
              boatName: vessel.name,
              eventType: vessel.eventType,
              timestamp: vessel.details?.time,
              vesselId: vessel.vessel_id,
            }));

            const vesselsWithCoords = this.vessels.filter(
              (vessel) => vessel.point.lat != null && vessel.point.lon != null
            );

            if (vesselsWithCoords.length > 0) {
              this.center = [
                12.548883, -81.718130
                //vesselsWithCoords[0].point.lat,
                //vesselsWithCoords[0].point.lon,
              ];
            }
            this.Loading = false;
            this.showModal= false;
         /*   Swal.fire({
              title: 'Consulta Exitosa',
              text: 'Los datos han sido obtenidos exitosamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar',
            });*/

          } else {
            Swal.fire({
              title: 'Sin Resultados',
              text: 'No se encontraron datos para las áreas seleccionadas.',
              icon: 'warning',
              confirmButtonText: 'Aceptar',
            });
            this.showModal= false;
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un problema al realizar la consulta.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
          this.showModal= false;
        }
      },


      async consultarTodasZona() {
        this.routes.forEach((route) => route.remove());
        this.routes = [];
        this.decorators.forEach((decorator) => decorator.remove());
        this.decorators = [];
        this.Loading = true;
        if (!this.todaszonasSeleccionadas || this.todaszonasSeleccionadas.length === 0 || !this.fechaInicio || !this.fechaFin) {
          Swal.fire({
            title: 'Error',
            text: 'Por favor, completa todos los campos.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
          return;
        }

        const areasAConsultar = this.todaszonasSeleccionadas.includes('all')
          ? this.areas.map((area) => area.aoi_id)
          : this.todaszonasSeleccionadas;

        const resultados = [];
        const errores = [];

        try {
          for (const aoiId of areasAConsultar) {
            const requestBody = {
              startTime: new Date(this.fechaInicio).toISOString(),
              endTime: new Date(this.fechaFin).toISOString(),
              aoiId,
            };

            try {
              const response = await axios.post(eventsQueryByArea, requestBody);

              const datosProcesados = await Promise.all(
                response.data.events.flatMap(async (event) => {
                  try {
                    const primerasCoordenadas = await this.obtenerPrimerasCoordenadas(event.event_id);
                    const coordenadaSeleccionada = primerasCoordenadas[0] || null;

                    return (event.vessels || []).map((vessel) => {
                      const latitude = coordenadaSeleccionada?.lat || vessel.details?.latitude;
                      const longitude = coordenadaSeleccionada?.lon || vessel.details?.longitude;

                      this.ubicacion.lat = latitude;
                      this.ubicacion.lon = longitude;

                      return {
                        eventId: event.event_id,
                        eventType: event.event_type,
                        startTime: event.start?.time,
                        endTime: event.end?.time,
                        name: vessel.name,
                        country: vessel.display_country,
                        mmsi: vessel.mmsi,
                        vessel_id: vessel.vessel_id,
                        details: vessel.details,
                        point: {
                          lat: latitude,
                          lon: longitude,
                        },
                        hasValidCoordinates: latitude != null && longitude != null,
                      };
                    });
                  } catch (error) {

                    return [];
                  }
                })
              );

              resultados.push(...datosProcesados.flat());
            } catch (error) {
              errores.push(aoiId);
            }
          }

          if (resultados.length > 0) {
            this.allVessels = resultados;
            this.vessels = [...this.allVessels];

            this.tableData = this.vessels.map((vessel) => ({
              boatName: vessel.name,
              eventType: vessel.eventType,
              timestamp: vessel.details?.time,
              vesselId: vessel.vessel_id,
            }));

            const vesselsWithCoords = this.vessels.filter(
              (vessel) => vessel.point.lat != null && vessel.point.lon != null
            );

            if (vesselsWithCoords.length > 0) {
              this.center = [
                12.548883, -81.718130
               // vesselsWithCoords[0].point.lat,
               // vesselsWithCoords[0].point.lon,
              ];
            }
            this.Loading = false;
/*            Swal.fire({
              title: 'Consulta Exitosa',
              text: 'Los datos han sido obtenidos exitosamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar',
            });*/
          } else {
            this.Loading = false;
            Swal.fire({
              title: 'Sin Resultados',
              text: 'No se encontraron datos para las áreas seleccionadas.',
              icon: 'warning',
              confirmButtonText: 'Aceptar',
            });
          }
        } catch (error) {
          this.Loading = false;
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un problema al realizar la consulta.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      },

      async mostrarEventos(event) {

        try {
          this.routes.forEach((route) => route.remove());
          this.decorators.forEach((decorator) => decorator.remove());
          this.routes = [];
          this.decorators = [];

          this.Loading = true;
          this.selectedVessel = event;
          this.showEventsButton = true;

          const response = await axios.post(vesselTrackingAndHistory, { eventId: event.eventId });

          if (response.data?.data?.length > 0) {
            response.data.data.forEach((data) => {
              if (Array.isArray(data.geometry?.coordinates) && data.geometry.coordinates.length > 0) {
                const coordinates = data.geometry.coordinates.map((coord) => [coord[1], coord[0]]);

                const routeLayer = L.polyline(coordinates, {
                  color: "blue",
                  weight: 3,
                  opacity: 0.7,
                }).addTo(this.map);
                this.routes.push(routeLayer);

                const decorator = L.polylineDecorator(routeLayer, {
                  patterns: [
                    {
                      offset: "50%",
                      repeat: 0,
                      symbol: L.Symbol.arrowHead({
                        pixelSize: 10,
                        polygon: true,
                        pathOptions: { color: "black", fillOpacity: 0.8, weight: 1 },
                      }),
                    },
                  ],
                }).addTo(this.map);
                this.decorators.push(decorator);
              }
            });
          }

          // Ajustar los límites del mapa
          if (this.routes.length > 0) {
            const unifiedBounds = this.routes
              .map((route) => route.getBounds())
              .reduce((acc, bounds) => acc.extend(bounds), L.latLngBounds());

            if (unifiedBounds.isValid()) {
              this.map.fitBounds(unifiedBounds);
            }
          }
        } catch (error) {

          this.showAlert = true;
        } finally {
          this.Loading = false;
        }
      },

      async obtenerPrimerasCoordenadas(eventId) {
        try {
          const response = await axios.post(vesselTrackingAndHistory, { eventId });

          if (response.data?.data?.length > 0) {
            for (const data of response.data.data) {
              if (data.geometry?.coordinates && data.geometry.coordinates.length > 0) {
                const primerasCoordenadas = data.geometry.coordinates.slice(0, 3).map((coord) => ({
                  lat: coord[1],
                  lon: coord[0],
                }));

                return primerasCoordenadas;
              }
            }
          }


          return [];
        } catch (error) {

          return [];
        }
      },


      formatDate(date) {
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        };
        return new Date(date).toLocaleDateString("es-ES", options);
      },



      traducirTipoEvento(eventType) {
        const evento = this.itemsTipoEvento.find((item) => item.value === eventType);
        return evento ? evento.text : "Tipo de evento desconocido";
      },
      getIconName(eventType) {
        const evento = this.itemsTipoEvento.find((item) => item.value === eventType);
        return evento ? evento.icon : "mdi-help-circle";
      },
      limpiarDecoraciones() {
        if (this.decorators.length > 0) {
          this.decorators.forEach(decorator => {
            this.map.removeLayer(decorator);
          });
          this.decorators = [];
        }
      },

      redirectToVessel(vesselId) {
        const baseUrl = "https://sc-production.skylight.earth/vesseldetails";

        const endTime = new Date().toISOString();
        const startTime = new Date(Date.now() - 48 * 60 * 60 * 1000).toISOString();

        const url = `${baseUrl}/${vesselId}?startTime=${encodeURIComponent(
          startTime
        )}&endTime=${encodeURIComponent(endTime)}`;

        window.open(url, "_blank");
      },

      getIconUrl(eventType) {
        const iconMap = {
          'dark_rendezvous': this.dark,
          'fishing_activity_history': this.history,
          'viirs': this.imag,
          'detection': this.detection,
          'standard_rendezvous':  this.standard,
          'speed_range': this.speed,
          'aoi_visit' : this.entry,
          default: this.defaul,
        };
        return iconMap[eventType] || iconMap.default;
      },

    },
   mounted() {
      if (this.$refs.mapLeaflet && this.$refs.mapLeaflet.mapObject) {
        this.map = this.$refs.mapLeaflet.mapObject;
      }
     this.consultaEmbarcacion.event_types = this.itemsTipoEvento.map((item) => item.value);
    },
    watch: {
      '$route.query.boat_name': {
        immediate: true,
        handler() {
          if (this.isVesselsReady) {
            this.centrarMapaEnBarco();
          } else {
            this.$watch(
              () => this.isVesselsReady,
              (ready) => {
                if (ready) {
                  this.centrarMapaEnBarco();
                }
              }
            );
          }
        },
      },
      '$route.params.cod_tracing': function () {
       this.loadData();
      }
    }


  };
</script>

<style scoped>

  .map-card {
    background-color: #fffdfd;
    border-radius: 8px;
  }

  .map-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 100px);
  }

  @media (max-width: 960px) {
    .map {
      height: calc(100vh - 200px);
    }
  }

  .responsive-buttons {
    gap: 8px;
    flex-wrap: wrap;
  }

  .btn-responsive {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 600px) {
    .button-text {
      font-size: 0.85rem;
    }
  }

  .drawer-mobile {
    width: 100% !important;
  }

  @media (max-width: 600px) {
    .custom-alert {
      width: 90%;
    }
  }

  .custom-alert {
    background-color: #3b82f6;
    color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    width: 50%;
  }

  .custom-alert .v-icon {
    animation: popIn 0.5s ease-out;
  }

  @keyframes popIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .v-data-table td {
    padding: 12px !important;
  }

  .v-data-table thead th {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #f5f5f5;
    color: #424242;
    padding: 10px;
  }

  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .spinner-box {
    width: 300px;
    height: 150px;
    background-color: #1A237E;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top: 4px solid #1A237E;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .btn-embarcacion {
    width: 100%;
    max-width: 400px;
  }

  .responsive-buttons {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    padding: 16px;
  }

  .btn-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    gap: 8px;
    white-space: nowrap;
    text-align: center;
    font-size: 1rem;
  }

  .button-text {
    font-size: 0.9rem;
  }

  @media (max-width: 600px) {
    .button-text {
      font-size: 0.85rem;
    }
  }

  .map-container {
    position: relative;
    width: 100%;
  }

  .map {
    border-radius: 8px;
    width: 100%;
    z-index: 1;
  }

  .event-types-container {
    flex-wrap: wrap;
    gap: 8px;
  }

  .event-type-item {
    margin-bottom: 8px;
  }

  .event-type-text {
    font-size: 0.85rem;
    white-space: nowrap;
  }

  @media (max-width: 600px) {
    .event-type-text {
      font-size: 0.75rem;
    }
  }

  .navigation-drawer {
    position: absolute;
    bottom: 0;
    z-index: 100;
    right: -4.2%;
    min-height: 60vh;
    max-height: 90vh;
    height: 80.5vh;
  }

  .drawer-mobile {
    width: 100% !important;
    max-width: 300px;
    right: 0 !important;
  }

  .event-list-item {
    margin-bottom: 8px;
  }

  .external-link-btn {
    width: 100%;
    margin: 4px;
  }

  .popup-content {
    padding: 8px;
    max-width: 250px;
  }

  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .spinner-box {
    background: #1A237E;
    padding: 20px;
    border-radius: 8px;
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #1A237E;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .custom-alert {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    max-width: 400px;
  }

</style>

